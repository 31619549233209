import './style.css';
import '../pr/style.css';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://bc71b6f1f341438c8008bb57352a42cc@o83192.ingest.sentry.io/6199964',
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_APP_SENTRY_RELEASE
});
window.Sentry = Sentry;

import $ from 'jquery/dist/jquery';
window.jQuery = window.$ = $;

$.urlParam = function (name) {
  const QUERY_PARAM = '[?&]';
  const QUERY_VALUE = '=([^&#]*)';
  const results = new RegExp(QUERY_PARAM + name + QUERY_VALUE).exec(window.location.href);
  if (results == null) {
    return null;
  } else {
    return results[1] || 0;
  }
};
